import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Section from '../components/Section';

import ContactUs from '../views/ContactUs';

function ContactPage() {
  return (
    <Layout>
      <SEO title="Contacto" />
      <Section
        title="Contacto"
        sectionClass="uk-section-small"
        containerClass="uk-container-xsmall"
      >
        <ContactUs />
      </Section>
    </Layout>
  );
}

export default ContactPage;
